// @flow
/* eslint-env browser */
import React, { Component } from 'react';
import styled from 'styled-components';

import type { Error } from '../state/types/error';

const Message = styled.div`
  color: ${({ theme }) => theme.colours.action};
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 10px 30px;
`;

type ErrorMessageWithScrollToProps = {
  error: Error,
  scroll: boolean,
};

export default class ErrorMessageWithScrollTo extends Component<ErrorMessageWithScrollToProps, *> {
  static defaultProps = {
    scroll: true,
  };

  componentDidMount() {
    if (this.props.scroll) window.scrollTo(0, 0);
  }

  render() {
    const { error } = this.props;

    const data = error.data;
    const message = typeof error.message === 'string' ? error.message : 'Unknown error';

    return (
      <Message>
        <h2>{message}</h2>
        {data && Array.isArray(data) && data.length > 0 && (
          <ul>
            {data.map(validation => (
              <li key={validation.property}>
                {validation.property}:{validation.message}
              </li>
            ))}
          </ul>
        )}
      </Message>
    );
  }
}
