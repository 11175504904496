'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EditableList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactSortableHoc = require('react-sortable-hoc');

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _DragHandle = require('./DragHandle');

var _DragHandle2 = _interopRequireDefault(_DragHandle);

var _EditText = require('../EditText');

var _EditText2 = _interopRequireDefault(_EditText);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var EditList = _styledComponents2.default.ul.withConfig({
  displayName: 'EditList'
}).withConfig({
  displayName: 'EditableList__EditList',
  componentId: 'sc-1l8d821-0'
})(['list-style-type:none;padding:0;']);

var ListContainer = (0, _styledComponents2.default)((0, _reactSortableHoc.SortableContainer)(function (_ref) {
  var children = _ref.children;
  return _react2.default.createElement(EditList, null, children);
})).withConfig({
  displayName: 'ListContainer'
}).withConfig({
  displayName: 'EditableList__ListContainer',
  componentId: 'sc-1l8d821-1'
})(['']);
var IconWrapper = (0, _styledComponents2.default)(_DragHandle2.default).withConfig({
  displayName: 'EditableList__IconWrapper',
  componentId: 'sc-1l8d821-2'
})(['min-width:12px;min-height:14px;margin:4px;']);
var DragHandleIcon = (0, _reactSortableHoc.SortableHandle)(function () {
  return _react2.default.createElement(IconWrapper, null);
});

var NoBulletListItem = _styledComponents2.default.li.withConfig({
  displayName: 'EditableList__NoBulletListItem',
  componentId: 'sc-1l8d821-3'
})(['list-style-type:none;display:flex;justify-content:space-between;[class^=\'EditTextContainer\']{flex:1 0 0%;}']);

var SortableItem = (0, _reactSortableHoc.SortableElement)(function (_ref2) {
  var hideIcon = _ref2.hideIcon,
      children = _ref2.children;
  return _react2.default.createElement(NoBulletListItem, null, !hideIcon && _react2.default.createElement(DragHandleIcon, null), children);
});
var EditItem = (0, _styledComponents2.default)(SortableItem).withConfig({
  displayName: 'EditItem'
}).withConfig({
  displayName: 'EditableList__EditItem',
  componentId: 'sc-1l8d821-4'
})(['list-style-type:none;margin:0;padding:0;display:inline-block;margin-bottom:29px;']);

function EditableList(_ref3) {
  var items = _ref3.items,
      itemBeingEdited = _ref3.itemBeingEdited,
      handleEdit = _ref3.onEdit,
      handleChange = _ref3.onChange,
      handleDelete = _ref3.onDelete,
      handleSort = _ref3.onSort;
  var draggable = !handleSort;
  return _react2.default.createElement('div', null, _react2.default.createElement(ListContainer, {
    useDragHandle: true,
    items: items,
    onSortEnd: handleSort
  }, items.length > 0 && items.map(function (item, index) {
    return _react2.default.createElement(EditItem, {
      key: item.navId,
      index: index,
      value: item,
      hideIcon: itemBeingEdited === item.navId,
      disabled: draggable
    }, _react2.default.createElement(_EditText2.default, {
      value: item.label,
      editing: itemBeingEdited === item.navId,
      onChange: handleChange(item.navId),
      onEdit: handleEdit(item.navId),
      onDelete: handleDelete(item.navId)
    }));
  })));
}

EditableList.defaultProps = {
  items: [],
  onChange: function onChange() {},
  onEdit: function onEdit() {},
  onDelete: function onDelete() {}
};