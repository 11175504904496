// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'redux-form';

import {
  Button,
  Fieldset,
  FieldListRow,
  FieldList,
  InputField,
  Label,
  Link,
  SectionHeading,
} from 'cms-ui';

const LeftPad = styled.span`
  margin-left: 4px;
`;
const InlineFormContainer = styled.div`
  display: flex;
`;

/*
 * Displays the value associated with a redux-form field without providing edit behaviour.
 * Used to build a list of immutable items that can be added / removed with FieldArray.
 */

function FixedTextField({ input }: { input: { value: string } }): Node {
  return <span>{input.value}</span>;
}

/*
 * `renderFieldRows` needs to:
 * - conform to the API for `FieldArray`
 * - be able to dispatch a `removeUser` action
 *
 * I've resolved this by wrapping the `FieldArray` callback in a closure that knows about
 * `removeUser` - alternative suggestions welcome. [31/10/17]
 */

function createUserFields({ removeUser }): Function {
  return ({ fields }: { fields: string[] }) => (
    <FieldList>
      {fields.map((user, index) => (
        <FieldListRow key={user}>
          <Field name={`${user}`} component={FixedTextField} />
          <LeftPad>
            <Link onClick={() => removeUser(index)}>remove</Link>
          </LeftPad>
        </FieldListRow>
      ))}
    </FieldList>
  );
}

const ifKeyIsEnter = callback => event => {
  if (event.key === 'Enter') {
    event.preventDefault();
    callback();
  }
};

const AddUserForm = ({ addUser }: { addUser: Function }) => (
  <div>
    <Label htmlFor="address">Invite by email address</Label>
    <InlineFormContainer onKeyDown={ifKeyIsEnter(() => addUser())}>
      <Field large type="text" name="address" component={InputField} />
      <LeftPad>
        <Button onClick={addUser}>Add User</Button>
      </LeftPad>
    </InlineFormContainer>
  </div>
);

const Users = ({ addUser, removeUser }: { addUser: Function, removeUser: Function }) => {
  const UserFields = createUserFields({ removeUser });
  return (
    <Fieldset>
      <SectionHeading>USERS</SectionHeading>
      <AddUserForm addUser={addUser} />
      <FieldArray name="users" component={UserFields} />
    </Fieldset>
  );
};

export default Users;
