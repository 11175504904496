// @flow
import type { Brand } from '../reducers/brand';
import { type Error } from '../types/error';

const CreateBrand: 'CREATE BRAND' = 'CREATE BRAND';
export type CreateBrandAction = { type: typeof CreateBrand, payload: Brand };
const createBrand = (brand: Brand): CreateBrandAction => ({
  type: CreateBrand,
  payload: brand,
});

const CreateBrandFailure: 'CREATE BRAND FAILURE' = 'CREATE BRAND FAILURE';
export type CreateBrandFailureAction = { type: typeof CreateBrandFailure, payload: Error };
const createBrandFailure = (payload: Error): CreateBrandFailureAction => ({
  type: CreateBrandFailure,
  payload,
});

const CreateBrandSuccessful: 'CREATE BRAND SUCCESS' = 'CREATE BRAND SUCCESS';
export type CreateBrandSuccessfulAction = { type: typeof CreateBrandSuccessful, payload: Object };
const createBrandSuccessful = (brand: Object): CreateBrandSuccessfulAction => ({
  type: CreateBrandSuccessful,
  payload: brand,
});

const LoadBrand: 'LOAD BRAND' = 'LOAD BRAND';
export type LoadBrandAction = { type: typeof LoadBrand, payload: string };
const loadBrand = (id: string): LoadBrandAction => ({ type: LoadBrand, payload: id });

const LoadBrandFailure: 'BRAND LOAD FAILURE' = 'BRAND LOAD FAILURE';
export type LoadBrandFailureAction = {
  type: typeof LoadBrandFailure,
  payload: Error,
};
const loadBrandFailure = (error: Error): LoadBrandFailureAction => ({
  type: LoadBrandFailure,
  payload: error,
});

const LoadBrandSuccessful: 'BRAND LOAD SUCCESSFUL' = 'BRAND LOAD SUCCESSFUL';
export type LoadBrandSuccessfulAction = {
  type: typeof LoadBrandSuccessful,
  payload: Brand,
};
const loadBrandSuccessful = (brand: Brand): LoadBrandSuccessfulAction => ({
  type: LoadBrandSuccessful,
  payload: brand,
});

const LoadBrands: 'LOAD BRANDS' = 'LOAD BRANDS';
export type LoadBrandsAction = { type: typeof LoadBrands };
const loadBrands = (): LoadBrandsAction => ({ type: LoadBrands });

const LoadBrandsFailure: 'BRANDS LOAD FAILURE' = 'BRANDS LOAD FAILURE';
export type LoadBrandsFailureAction = {
  type: typeof LoadBrandsFailure,
  payload: Error,
};
const loadBrandsFailure = (error: Error): LoadBrandsFailureAction => ({
  type: LoadBrandsFailure,
  payload: error,
});

const LoadBrandsSuccessful: 'BRANDS LOAD SUCCESSFUL' = 'BRANDS LOAD SUCCESSFUL';
export type LoadBrandsSuccessfulAction = {
  type: typeof LoadBrandsSuccessful,
  payload: Brand[],
};
const loadBrandsSuccessful = (brands: Brand[]): LoadBrandsSuccessfulAction => ({
  type: LoadBrandsSuccessful,
  payload: brands,
});

const UpdateBrand: 'UPDATE BRAND' = 'UPDATE BRAND';
export type UpdateBrandAction = { type: typeof UpdateBrand, payload: Brand };
const updateBrand = (brand: Brand): UpdateBrandAction => ({
  type: UpdateBrand,
  payload: brand,
});

const UpdateBrandFailure: 'UPDATE BRAND FAILURE' = 'UPDATE BRAND FAILURE';
export type UpdateBrandFailureAction = { type: typeof UpdateBrandFailure, payload: Error };
const updateBrandFailure = (payload: Error): UpdateBrandFailureAction => ({
  type: UpdateBrandFailure,
  payload,
});

const UpdateBrandSuccessful: 'UPDATE BRAND SUCCESS' = 'UPDATE BRAND SUCCESS';
export type UpdateBrandSuccessfulAction = { type: typeof UpdateBrandSuccessful, payload: Object };
const updateBrandSuccessful = (brand: Object): UpdateBrandSuccessfulAction => ({
  type: UpdateBrandSuccessful,
  payload: brand,
});

export const actions = {
  createBrand,
  createBrandFailure,
  createBrandSuccessful,
  loadBrand,
  loadBrandFailure,
  loadBrandSuccessful,
  loadBrands,
  loadBrandsFailure,
  loadBrandsSuccessful,
  updateBrand,
  updateBrandFailure,
  updateBrandSuccessful,
};

export const constants = {
  CreateBrand,
  CreateBrandFailure,
  CreateBrandSuccessful,
  LoadBrand,
  LoadBrandFailure,
  LoadBrands,
  LoadBrandsFailure,
  LoadBrandsSuccessful,
  LoadBrandSuccessful,
  UpdateBrand,
  UpdateBrandFailure,
  UpdateBrandSuccessful,
};

export type Action =
  | CreateBrandAction
  | CreateBrandFailureAction
  | CreateBrandSuccessfulAction
  | LoadBrandAction
  | LoadBrandFailureAction
  | LoadBrandsAction
  | LoadBrandsFailureAction
  | LoadBrandsSuccessfulAction
  | LoadBrandSuccessfulAction
  | UpdateBrandAction
  | UpdateBrandFailureAction
  | UpdateBrandSuccessfulAction;
