export const constants = {
  addUser: 'USER_ADD',
  addUserSuccess: 'USER_ADD_SUCCESS',
  addUserFailure: 'USER_ADD_FAILURE',
  removeUser: 'USER_REMOVE',
};

export const actions = {
  addUser: () => ({ type: constants.addUser }),
  addUserSuccess: address => ({ type: constants.addUserSuccess, payload: { address } }),
  addUserFailure: error => ({ type: constants.addUserFailure, payload: { error } }),
  removeUser: index => ({ type: constants.removeUser, payload: { index } }),
};
