// @flow
import { put, call } from 'redux-saga/effects';

import { actions, constants } from '../actions/language';
import { get } from '../../helpers/http';
import { API_BASE_URL_LANGUAGES } from '../../constants/apis';
import watch from '../../helpers/watch';

export function* loadLanguages(token: string): Generator<*, *, *> {
  try {
    // Typed as any because Flow thinks response is a string for some reason
    const response: any = yield call(get, { url: API_BASE_URL_LANGUAGES, token });
    yield put(actions.loadLanguagesSuccess(response.data.contents));
  } catch (error) {
    yield put(actions.loadLanguagesFailure({ message: 'Failed to load languages' }));
  }
}

export default [watch(constants.LoadLanguages, loadLanguages, true)];
