'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _templateObject = _taggedTemplateLiteral(['\n  display: flex;\n  flex-direction: column;\n  font-family: ', ';\n'], ['\n  display: flex;\n  flex-direction: column;\n  font-family: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  padding-top: ', ';\n'], ['\n  padding-top: ', ';\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CheckboxSwitch = require('./CheckboxSwitch');

var _CheckboxSwitch2 = _interopRequireDefault(_CheckboxSwitch);

var _InputField = require('./InputField');

var _InputField2 = _interopRequireDefault(_InputField);

var _FieldLabel = require('./FieldLabel');

var _FieldLabel2 = _interopRequireDefault(_FieldLabel);

var _Label = require('./Label');

var _Label2 = _interopRequireDefault(_Label);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({
    displayName: displayName
  });
};

var LinkContainer = namedDiv('LinkContainer')(_templateObject, function (props) {
  return '' + props.theme.fontFamily;
});
var LinkContainerInputs = namedDiv('LinkContainerInputs')(_templateObject2, function (props) {
  return '' + props.theme.layout.standardPadding;
});

var LabelContainer = _styledComponents2.default.span.withConfig({
  displayName: 'LabelContainer'
}).withConfig({
  displayName: 'LinkEditor__LabelContainer',
  componentId: 'qk5zfn-0'
})(['display:flex;padding-top:', ';', ';'], function (props) {
  return '' + props.theme.layout.standardPadding;
}, function (props) {
  return props.labelName ? 'padding-bottom: ' + props.theme.layout.standardPadding : '';
});

var LinkEditor = function (_React$Component) {
  _inherits(LinkEditor, _React$Component);

  function LinkEditor(props) {
    _classCallCheck(this, LinkEditor);

    var _this = _possibleConstructorReturn(this, (LinkEditor.__proto__ || Object.getPrototypeOf(LinkEditor)).call(this, props));

    _this.state = {
      disabled: _this.props.disabled
    };
    return _this;
  }

  _createClass(LinkEditor, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          name = _props.name,
          onChange = _props.onChange,
          value = _props.value,
          labelName = _props.labelName;

      var toggle = function toggle() {
        return _this2.setState({
          disabled: !_this2.state.disabled
        });
      };

      return _react2.default.createElement(LinkContainer, null, _react2.default.createElement(_FieldLabel2.default, {
        label: name,
        inputFlexMode: '0'
      }, _react2.default.createElement(_CheckboxSwitch2.default, {
        name: name + '-toggle',
        onChange: function (_onChange) {
          function onChange() {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function () {
          toggle();
          onChange({
            url: '',
            label: ''
          });
        }),
        value: !this.state.disabled
      })), !this.state.disabled ? _react2.default.createElement(LinkContainerInputs, null, _react2.default.createElement(_InputField2.default, {
        input: {
          onChange: function (_onChange2) {
            function onChange(_x) {
              return _onChange2.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange2.toString();
            };

            return onChange;
          }(function (e) {
            return onChange({
              url: e.target.value,
              label: value.label
            });
          }),
          name: name + '-url-input',
          value: value.url
        },
        type: 'text',
        placeholder: 'http://...'
      }), _react2.default.createElement(LabelContainer, {
        labelName: labelName
      }, labelName && _react2.default.createElement(_Label2.default, null, labelName)), _react2.default.createElement(_InputField2.default, {
        input: {
          onChange: function (_onChange3) {
            function onChange(_x2) {
              return _onChange3.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange3.toString();
            };

            return onChange;
          }(function (e) {
            return onChange({
              url: value.url,
              label: e.target.value
            });
          }),
          name: name + '-label-input',
          value: value.label
        },
        type: 'text'
      })) : null);
    }
  }]);

  return LinkEditor;
}(_react2.default.Component);

LinkEditor.defaultProps = {
  disabled: false,
  name: '',
  labelName: '',
  value: {},
  onChange: function onChange() {}
};
exports.default = LinkEditor;