'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ScreenSizePicker;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _monitor = require('react-icons/lib/io/monitor');

var _monitor2 = _interopRequireDefault(_monitor);

var _ipad = require('react-icons/lib/io/ipad');

var _ipad2 = _interopRequireDefault(_ipad);

var _iphone = require('react-icons/lib/io/iphone');

var _iphone2 = _interopRequireDefault(_iphone);

var _LargeIconRadioGroup = require('./LargeIconRadioGroup');

var _LargeIconRadioGroup2 = _interopRequireDefault(_LargeIconRadioGroup);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var options = [{
  element: _react2.default.createElement(_monitor2.default, null),
  value: 'desktop'
}, {
  element: _react2.default.createElement(_ipad2.default, null),
  value: 'tablet',
  rotate: 90
}, {
  element: _react2.default.createElement(_iphone2.default, null),
  value: 'mobile'
}];

function ScreenSizePicker(_ref) {
  var onChange = _ref.onChange,
      selected = _ref.selected;
  return _react2.default.createElement(_LargeIconRadioGroup2.default, {
    name: 'ScreenSizePicker',
    options: options,
    selected: selected,
    onChange: onChange
  });
}

ScreenSizePicker.defaultProps = {
  selected: 'desktop'
};