'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckboxLabel = exports.CheckboxInput = undefined;
exports.default = Checkbox;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CheckboxInput = exports.CheckboxInput = _styledComponents2.default.input.attrs({
  type: 'checkbox'
}).withConfig({
  displayName: 'Checkbox__CheckboxInput',
  componentId: 'dposw6-0'
})(['height:16px;width:16px;margin-right:4px;border:1px solid ', ';background:#fff;'], function (props) {
  return props.theme.colours.secondary03;
});

var CheckboxLabel = exports.CheckboxLabel = _styledComponents2.default.label.withConfig({
  displayName: 'Checkbox__CheckboxLabel',
  componentId: 'dposw6-1'
})(['min-height:20px;margin-bottom:10px;font-weight:400;cursor:pointer;display:block;']); // This is a custom component for Redux Form Field component
// and is used by passing it to the Field's component prop
// input and meta are props passed from the Field component


function Checkbox(_ref) {
  var name = _ref.name,
      checked = _ref.checked,
      label = _ref.label,
      onChange = _ref.onChange;
  return _react2.default.createElement(CheckboxLabel, null, _react2.default.createElement(CheckboxInput, {
    name: name,
    checked: checked,
    onChange: function (_onChange) {
      function onChange() {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function () {
      return onChange(!checked);
    })
  }), label);
}

Checkbox.defaultProps = {
  name: null
};