'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckboxSwitch;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var i = 0;

var Container = _styledComponents2.default.label.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'CheckboxSwitch__Container',
  componentId: 'sc-1a7ifw6-0'
})(['font-family:', ';height:20px;display:flex;justify-content:flex-end;min-width:54px;border:1px solid ', ';box-shadow:0 2px 6px 0 rgba(0,0,0,0.1);padding:1px;cursor:', ';transition:border 0.35s ease-in-out;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (props) {
  return props.value ? props.theme.colours.connectAutoBlue : props.theme.colours.secondary02;
}, function (_ref2) {
  var disabled = _ref2.disabled;
  return disabled ? 'not-allowed' : 'pointer';
});

var Slider = _styledComponents2.default.div.withConfig({
  displayName: 'CheckboxSwitch__Slider',
  componentId: 'sc-1a7ifw6-1'
})(['display:flex;width:50%;align-items:center;justify-content:space-between;transform:translatex(', ');transition:transform 0.35s ease-in-out,background 0.35s ease-in-out;background:', ';'], function (_ref3) {
  var value = _ref3.value;
  return value ? '0' : '-100%';
}, function (props) {
  return props.value ? props.theme.colours.connectAutoBlue : props.theme.colours.secondary02;
});

var ComponentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CheckboxSwitch__ComponentContainer',
  componentId: 'sc-1a7ifw6-2'
})(['display:flex;font-size:10px;flex:1 0 0%;justify-content:center;color:', ';padding:1px;margin:0;'], function (props) {
  return props.theme.colours.primary03;
});

var HiddenCheckbox = _styledComponents2.default.input.withConfig({
  displayName: 'HiddenCheckbox'
}).attrs({
  type: 'checkbox'
}).withConfig({
  displayName: 'CheckboxSwitch__HiddenCheckbox',
  componentId: 'sc-1a7ifw6-3'
})(['display:none;']);

function CheckboxSwitch(_ref4) {
  var value = _ref4.value,
      onChange = _ref4.onChange,
      onComponent = _ref4.onComponent,
      offComponent = _ref4.offComponent,
      name = _ref4.name,
      disabled = _ref4.disabled;
  var id = 'checkbox-' + (i += 1);
  return _react2.default.createElement(Container, {
    value: value,
    htmlFor: id,
    disabled: disabled
  }, _react2.default.createElement(HiddenCheckbox, {
    id: id,
    name: name,
    value: value,
    onChange: onChange,
    disabled: disabled
  }), _react2.default.createElement(Slider, {
    value: value
  }, _react2.default.createElement(ComponentContainer, null, value ? onComponent : offComponent)));
}

CheckboxSwitch.defaultProps = {
  onComponent: _react2.default.createElement('span', null, 'ON'),
  offComponent: _react2.default.createElement('span', null, 'OFF'),
  onChange: function onChange() {},
  disabled: false
};