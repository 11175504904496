/* eslint-env browser */
// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button } from 'cms-ui';

import { actions } from '../state/actions/auth';
import { actions as routerActions } from '../state/actions/router';
import settings from '../settings';
import { getParentUrl } from '../constants/routes';

const Items = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

const Nav = styled.nav`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeSmall};
  background: ${({ theme }) => theme.colours.white};
  display: flex;
`;

type LogoutProps = {
  onLogout: Function,
  children?: Node,
  onNavigate: Function,
  parentUrl: string,
};

type CreateSiteProps = {
  login: Function,
  logout: Function,
  isLoggedIn: Boolean,
  children?: Node,
  onNavigate: Function,
  parent: string,
};

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(actions.login()),
  logout: () => dispatch(actions.logout()),
  onNavigate: route => dispatch(routerActions.navigate(route)),
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

const BackButton = ({ onNavigate, parentUrl }: { onNavigate: Function, parentUrl: string }) =>
  parentUrl && (
    <Button link onClick={() => onNavigate(parentUrl)}>
      BACK
    </Button>
  );

const Logout = ({ onLogout, children, onNavigate, parentUrl }: LogoutProps) => (
  <div>
    <Nav>
      <Items>
        <Button link onClick={settings.authOn ? onLogout : () => {}}>
          LOGOUT
        </Button>
        {parentUrl && <BackButton onNavigate={onNavigate} parentUrl={parentUrl} />}
      </Items>
    </Nav>
    {children}
  </div>
);

Logout.defaultProps = {
  children: null,
};

class LogoutWrapper extends React.Component<CreateSiteProps, *> {
  componentWillMount() {
    if (!this.props.isLoggedIn) {
      this.props.login();
    }
  }

  render() {
    const { children, isLoggedIn, logout, onNavigate, parent } = this.props;
    const currentPath = window.location.pathname;
    const parentUrl = getParentUrl(currentPath, parent);
    return isLoggedIn ? (
      <Logout onLogout={logout} onNavigate={onNavigate} parentUrl={parentUrl}>
        {children}
      </Logout>
    ) : (
      <div />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutWrapper);
