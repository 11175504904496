// @flow
import { constants, type Action } from '../actions/brand';
import { type Error } from '../types/error';

export type Brand = {
  id: string,
  name: string,
  domainName: string,
};

export type State = {
  isLoading: boolean,
  error: ?Error,
  brands: { [id: $PropertyType<Brand, 'id'>]: Brand },
};

const initialState: State = {
  isLoading: false,
  error: null,
  brands: {},
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case constants.LoadBrand:
    case constants.LoadBrands:
    case constants.CreateBrand:
      return { ...state, isLoading: true };

    case constants.CreateBrandSuccessful:
    case constants.LoadBrandSuccessful: {
      const brand = action.payload;
      return {
        ...state,
        brands: { ...state.brands, [brand.id]: brand },
        error: null,
        isLoading: false,
      };
    }
    case constants.LoadBrandsSuccessful: {
      const brands = action.payload;

      return {
        ...state,
        brands: brands.reduce((acc, b) => ({ ...acc, [b.id]: b }), {}),
        error: null,
        isLoading: false,
      };
    }

    case constants.LoadBrandFailure:
    case constants.LoadBrandsFailure:
    case constants.CreateBrandFailure:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
}
