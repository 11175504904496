'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

var _Column = require('./Column');

var _Column2 = _interopRequireDefault(_Column);

var _FormGroup = require('./FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var Container = _styledComponents2.default.div.withConfig({
  type: 'Container'
}).withConfig({
  displayName: 'SelectSubmit__Container',
  componentId: 'sc-19dr1l2-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var StyledSelect = (0, _styledComponents2.default)(_Select2.default).withConfig({
  displayName: 'StyledSelect'
}).withConfig({
  displayName: 'SelectSubmit__StyledSelect',
  componentId: 'sc-19dr1l2-1'
})(['']);
var AddButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'AddButton'
}).withConfig({
  displayName: 'SelectSubmit__AddButton',
  componentId: 'sc-19dr1l2-2'
})(['padding:6px 0;margin-top:3px;']);

var SelectSubmit = function (_Component) {
  _inherits(SelectSubmit, _Component);

  function SelectSubmit() {
    var _ref3;

    var _temp, _this, _ret;

    _classCallCheck(this, SelectSubmit);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref3 = SelectSubmit.__proto__ || Object.getPrototypeOf(SelectSubmit)).call.apply(_ref3, [this].concat(args))), _this), _this.state = {
      selectedOption: ''
    }, _this.defaultProps = {
      options: []
    }, _this.onChangeSelectedOption = function (selectedOption) {
      _this.setState({
        selectedOption: selectedOption
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SelectSubmit, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          options = _props.options,
          name = _props.name,
          onSubmit = _props.onSubmit,
          placeholder = _props.placeholder,
          selectedValue = _props.selectedValue;
      return _react2.default.createElement(Container, null, _react2.default.createElement(_FormGroup2.default, {
        horizontal: true
      }, _react2.default.createElement(_Column2.default, {
        width: '70%'
      }, _react2.default.createElement(StyledSelect, {
        selectedValue: selectedValue,
        name: 'select-' + name,
        options: options,
        onChange: function onChange(e) {
          return _this2.onChangeSelectedOption(e.target.value);
        },
        placeholder: placeholder
      })), _react2.default.createElement(_Column2.default, {
        paddingLeft: 'standardPadding',
        width: '30%'
      }, _react2.default.createElement(AddButton, {
        onClick: function onClick() {
          return onSubmit(_this2.state.selectedOption);
        },
        small: true,
        fullWidth: true,
        primary: true,
        textTransform: 'uppercase',
        disabled: !this.state.selectedOption
      }, 'Add'))));
    }
  }]);

  return SelectSubmit;
}(_react.Component);

exports.default = SelectSubmit;