// @flow
import { type State } from '../reducer';
import { type Error } from '../types/error';

const addKey = (error: ?Error, key: string): ?Error => (error ? { ...error, key } : null);

// we only care about fetch errors that (mostly) only happen on page loads
export default function allErrors({ site, language, brand }: State): Error[] {
  return [
    addKey(language.error, 'languages'),
    addKey(site.error, 'sites'),
    addKey(brand.error, 'brands'),
  ].filter(Boolean);
}
