'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = CreatePage;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _PageSeo = require('./PageSeo');

var _PageSeo2 = _interopRequireDefault(_PageSeo);

var _PageDetails = require('./PageDetails');

var _PageDetails2 = _interopRequireDefault(_PageDetails);

var _SectionMenuTitle = require('./SectionMenuTitle');

var _SectionMenuTitle2 = _interopRequireDefault(_SectionMenuTitle);

var _Accordion = require('./Accordion');

var _Accordion2 = _interopRequireDefault(_Accordion);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  type: 'Container'
}).withConfig({
  displayName: 'CreatePage__Container',
  componentId: 'ym8sfb-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ButtonContainer'
}).withConfig({
  displayName: 'CreatePage__ButtonContainer',
  componentId: 'ym8sfb-1'
})(['display:flex;flex-direction:column;margin:12px;']);

function CreatePage(_ref3) {
  var title = _ref3.title,
      pageTemplates = _ref3.pageTemplates,
      value = _ref3.value,
      onChange = _ref3.onChange,
      showAccordion = _ref3.showAccordion,
      ui = _ref3.ui,
      renderButton = _ref3.renderButton,
      canChangePageType = _ref3.canChangePageType;
  return _react2.default.createElement(Container, null, _react2.default.createElement(_SectionMenuTitle2.default, null, title), _react2.default.createElement(_Accordion2.default, {
    titleContents: _react2.default.createElement('div', null, 'Details'),
    bodyContents: _react2.default.createElement(_PageDetails2.default, {
      pageTemplates: pageTemplates,
      value: value,
      onChange: onChange,
      canChangeType: canChangePageType
    }),
    open: ui.createPageDetail,
    onTitleClick: function onTitleClick() {
      return showAccordion('createPageDetail', !ui.createPageDetail);
    },
    hasDisableToggle: false
  }), _react2.default.createElement(_Accordion2.default, {
    titleContents: _react2.default.createElement('div', null, 'SEO'),
    bodyContents: _react2.default.createElement(_PageSeo2.default, {
      value: value.seo,
      onChange: function (_onChange) {
        function onChange(_x) {
          return _onChange.apply(this, arguments);
        }

        onChange.toString = function () {
          return _onChange.toString();
        };

        return onChange;
      }(function (seo) {
        return onChange(_extends({}, value, {
          seo: seo
        }));
      })
    }),
    open: ui.createPageSeo,
    onTitleClick: function onTitleClick() {
      return showAccordion('createPageSeo', !ui.createPageSeo);
    },
    hasDisableToggle: false
  }), renderButton && _react2.default.createElement(ButtonContainer, null, renderButton(value)));
}