// @flow
import React from 'react';
import { connect } from 'react-redux';
import Brand from '../components/Brand';
import { actions as userActions } from '../state/actions/users';
import { actions as brandActions } from '../state/actions/brand';
import { actions as languageActions } from '../state/actions/language';
import { actions as routerActions } from '../state/actions/router';

const mapStateToProps = ({ language, brand }, props) => {
  const brandId = props.match.params.brandId;

  return {
    languages: language.languages,
    brand: brand.brands[brandId],
    error: brand.error,
  };
};

const mapDispatchToProps = dispatch => ({
  addUser: address => dispatch(userActions.addUser(address)),
  removeUser: index => dispatch(userActions.removeUser(index)),
  createBrand: brand => dispatch(brandActions.createBrand(brand)),
  updateBrand: brand => dispatch(brandActions.updateBrand(brand)),
  loadBrand: brandId => dispatch(brandActions.loadBrand(brandId)),
  loadLanguages: () => dispatch(languageActions.loadLanguages()),
  onNavigate: route => dispatch(routerActions.navigate(route)),
});

class BrandsWithLifeCycle extends React.Component<*, *> {
  componentDidMount() {
    this.props.loadLanguages();
    const brandId = this.props.match.params.brandId;
    if (brandId) {
      this.props.loadBrand(brandId);
    }
  }

  render() {
    return <Brand {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandsWithLifeCycle);
