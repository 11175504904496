'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InputLabel;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledInputLabel = _styledComponents2.default.span.withConfig({
  displayName: 'InputLabel'
}).withConfig({
  displayName: 'InputLabel__StyledInputLabel',
  componentId: 'sc-11wvtyt-0'
})(['', ';font-family:', ';border-width:1px;border-style:solid;', ';', ';', ';'], function (_ref) {
  var theme = _ref.theme,
      large = _ref.large;
  var fs = void 0; // Font size

  var fc = void 0; // Font colour

  var bc = void 0; // Border color

  var pv = void 0; // Padding vertically

  var ph = void 0; // Padding horizontally

  if (large) {
    fs = theme.fontSizeContent;
    fc = theme.colours.secondary05;
    bc = theme.colours.secondary02;
    ph = 10;
    pv = 10;
  } else {
    fs = theme.fontSizeFormControls;
    fc = theme.colours.secondary03;
    bc = theme.colours.secondary03;
    ph = 8;
    pv = 4;
  }

  return (0, _styledComponents.css)(['color:', ';font-size:', ';border-color:', ';padding:', 'px ', 'px ', 'px ', 'px;'], fc, fs, bc, pv, ph, pv, ph);
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.inputLabelPosition === 'left' && (0, _styledComponents.css)(['order:1;padding-left:5px;border-right:0;']);
}, function (props) {
  return props.inputLabelPosition === 'right' && (0, _styledComponents.css)(['order:2;padding-right:5px;border-left:0;']);
}, function (props) {
  return props.error && (0, _styledComponents.css)(['border-color:', ';'], function (props) {
    return props.theme.colours.danger01;
  });
});

function InputLabel(_ref2) {
  var inputLabelPosition = _ref2.inputLabelPosition,
      large = _ref2.large,
      error = _ref2.error,
      children = _ref2.children;
  return _react2.default.createElement(StyledInputLabel, {
    error: error,
    large: large,
    inputLabelPosition: inputLabelPosition
  }, children);
}

InputLabel.defaultProps = {
  inputLabelPosition: 'left',
  large: false,
  error: false,
  children: null
};