'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Button;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

var StyledButton = _styledComponents2.default.button.attrs({
  type: function type(_ref) {
    var _type = _ref.type;
    return _type || 'button';
  }
}).withConfig({
  displayName: 'Button__StyledButton',
  componentId: 'sc-14vgjf4-0'
})(['', ' ', ';border:none;cursor:', ';font-family:', ';font-size:', ';font-weight:', ';opacity:', ';text-transform:', ';outline:none;padding:', ';text-align:center;vertical-align:middle;', ' ', ';'], function (_ref2) {
  var link = _ref2.link;
  return link && (0, _styledComponents.css)(['text-decoration:underline;background-color:transparent;']);
}, function (_ref3) {
  var theme = _ref3.theme,
      disabled = _ref3.disabled,
      link = _ref3.link,
      primary = _ref3.primary,
      danger = _ref3.danger;
  var c = theme.colours;
  var bg = void 0; // Background

  var fg = void 0; // Foreground

  var hl = void 0; // Highlight

  var border = 'none';

  if (disabled) {
    bg = c.disabled01;
    hl = c.disabled01;
    fg = c.disabled02;
  } else if (primary) {
    bg = c.primary01;
    hl = c.primary02;
    fg = c.primary03;
  } else if (danger) {
    bg = c.danger01;
    hl = c.danger02;
    fg = c.danger03;
  } else {
    bg = c.secondary01;
    hl = c.secondary02;
    fg = c.secondary03;
    border = '1px solid ' + c.secondary03;

    if (link) {
      return (0, _styledComponents.css)(['color:', ';&:active{color:', ';}'], c.linkAction, hl);
    }
  }

  if (link) {
    return (0, _styledComponents.css)(['color:', ';&:active{color:', ';}'], bg, hl);
  }

  return (0, _styledComponents.css)(['background-color:', ';border:', ' !important;color:', ';&:active{background-color:', ';}'], bg, border, fg, hl);
}, function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled ? 'not-allowed' : 'pointer';
}, function (props) {
  return props.theme.fontFamily;
}, function (_ref5) {
  var small = _ref5.small,
      theme = _ref5.theme;
  return small ? theme.fontSizeSmall : theme.fontSizeContent;
}, function (_ref6) {
  var small = _ref6.small;
  return small ? 400 : 600;
}, function (_ref7) {
  var disabled = _ref7.disabled;
  return disabled ? '0.5' : '1';
}, function (_ref8) {
  var textTransform = _ref8.textTransform;
  return textTransform || 'none';
}, function (_ref9) {
  var small = _ref9.small;
  return small ? '6px 0' : '10px 5px';
}, function (_ref10) {
  var fullWidth = _ref10.fullWidth;
  return fullWidth && 'width: 100%;';
}, function (_ref11) {
  var link = _ref11.link;
  return !link && (0, _styledComponents.css)(['padding-left:30px;padding-right:30px;']);
});

var LabelButton = StyledButton.withComponent('label');

function Button(_ref12) {
  var label = _ref12.label,
      props = _objectWithoutProperties(_ref12, ['label']);

  if (label) return _react2.default.createElement(LabelButton, props);
  return _react2.default.createElement(StyledButton, props);
}

Button.defaultProps = {
  children: null,
  danger: false,
  disabled: false,
  fullWidth: false,
  label: false,
  link: false,
  primary: false,
  small: false
};