'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItem = exports.handleDelete = exports.handleSort = exports.updateItem = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _reactSortableHoc = require('react-sortable-hoc');

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var getItemById = function getItemById(items, id) {
  return items.find(function (item) {
    return item.id === id;
  });
};

var updateItem = exports.updateItem = function updateItem(navId, value, items) {
  var index = items.findIndex(function (p) {
    return p.navId === navId;
  });
  return [].concat(_toConsumableArray(items.slice(0, index)), [_extends({}, items[index], {
    label: value
  })], _toConsumableArray(items.slice(index + 1)));
};

var handleSort = exports.handleSort = function handleSort(items, oldIndex, newIndex) {
  return [].concat(_toConsumableArray((0, _reactSortableHoc.arrayMove)(items, oldIndex, newIndex)));
};

var handleDelete = exports.handleDelete = function handleDelete(navId, items) {
  return items.filter(function (item) {
    return item.navId !== navId;
  });
};

var addItem = exports.addItem = function addItem(id, data, items) {
  var itemToBeAdded = _extends({}, getItemById(data, id), {
    navId: (0, _v2.default)()
  });

  return [].concat(_toConsumableArray(items), [itemToBeAdded]);
};