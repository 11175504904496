// @flow
import { constants, type Action } from '../actions/language';
import type { Error } from '../types/error';

export type Language = any;

export type State = {
  isLoading: boolean,
  error: ?Error,
  languages: Language[],
};

const intialState: State = {
  isLoading: false,
  error: undefined,
  languages: [],
};

export default function reducer(state: State = intialState, action: Action): State {
  switch (action.type) {
    case constants.LoadLanguages:
      return { ...state, isLoading: true };
    case constants.LoadLanguagesSuccess:
      return { ...state, isLoading: false, languages: action.payload, error: undefined };
    case constants.LoadLanguagesFailure:
      return { ...state, isLoading: false, error: { message: 'Languages failed to load' } };
    default:
      return state;
  }
}
