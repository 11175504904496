'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = PageSeo;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _FormGroup = require('./FormGroup');

var _FormGroup2 = _interopRequireDefault(_FormGroup);

var _Input = require('./Input');

var _Input2 = _interopRequireDefault(_Input);

var _TextArea = require('./TextArea');

var _TextArea2 = _interopRequireDefault(_TextArea);

var _Label = require('./Label');

var _Label2 = _interopRequireDefault(_Label);

var _Column = require('./Column');

var _Column2 = _interopRequireDefault(_Column);

var _AnalyticsEditor = require('./AnalyticsEditor');

var _AnalyticsEditor2 = _interopRequireDefault(_AnalyticsEditor);

var _EditMany = require('./EditMany');

var _EditMany2 = _interopRequireDefault(_EditMany);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var Container = _styledComponents2.default.div.withConfig({
  type: 'Container'
}).withConfig({
  displayName: 'PageSeo__Container',
  componentId: 'qkof1m-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var analyticFactory = function analyticFactory() {
  return {
    id: (0, _v2.default)(),
    value: null
  };
};

var findIndexById = function findIndexById(list, id) {
  return list.findIndex(function (i) {
    return i.id === id;
  });
};

function PageSeo(_ref3) {
  var value = _ref3.value,
      onChange = _ref3.onChange;

  var patch = function patch(change) {
    return _extends({}, value, change);
  };

  var onAddAnalytic = function onAddAnalytic() {
    return onChange(patch({
      analytics: [].concat(_toConsumableArray(value.analytics), [analyticFactory()])
    }));
  };

  var onDeleteAnalytic = function onDeleteAnalytic(id) {
    var index = findIndexById(value.analytics, id);
    onChange(patch({
      analytics: [].concat(_toConsumableArray(value.analytics.slice(0, index)), _toConsumableArray(value.analytics.slice(index + 1)))
    }));
  };

  var onChangeAnalytic = function onChangeAnalytic(id, changed) {
    var index = findIndexById(value.analytics, id);
    onChange(patch({
      analytics: [].concat(_toConsumableArray(value.analytics.slice(0, index)), [_extends({}, value.analytics[index], {
        id: id,
        value: changed
      })], _toConsumableArray(value.analytics.slice(index + 1)))
    }));
  };

  return _react2.default.createElement(Container, null, _react2.default.createElement(_FormGroup2.default, {
    horizontal: true
  }, _react2.default.createElement(_Label2.default, {
    width: '30%'
  }, 'Title'), _react2.default.createElement(_Column2.default, {
    width: '70%'
  }, _react2.default.createElement(_Input2.default, {
    name: 'seo-page-title',
    type: 'text',
    onChange: function (_onChange) {
      function onChange(_x) {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function (e) {
      return onChange(patch({
        title: e.target.value
      }));
    }),
    value: value.title,
    placeholder: 'my-page',
    prefix: null
  }))), _react2.default.createElement(_FormGroup2.default, null, _react2.default.createElement(_Label2.default, null, 'Description'), _react2.default.createElement(_Column2.default, null, _react2.default.createElement(_TextArea2.default, {
    value: value.description,
    onChange: function (_onChange2) {
      function onChange(_x2) {
        return _onChange2.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange2.toString();
      };

      return onChange;
    }(function (description) {
      return onChange(patch({
        description: description
      }));
    }),
    placeholder: 'Type desired text...',
    rows: 8
  }))), _react2.default.createElement(_FormGroup2.default, null, _react2.default.createElement(_Label2.default, null, 'Analytics code'), _react2.default.createElement(_Column2.default, null, _react2.default.createElement(_EditMany2.default, {
    items: value.analytics,
    renderEditor: function renderEditor(item, handleChange) {
      return _react2.default.createElement(_AnalyticsEditor2.default, _extends({}, item, {
        onChange: handleChange
      }));
    },
    onAdd: onAddAnalytic,
    onDelete: onDeleteAnalytic,
    onItemChange: onChangeAnalytic,
    idFactory: function idFactory() {
      return (0, _v2.default)();
    }
  }))));
}