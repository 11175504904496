'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _templateObject = _taggedTemplateLiteral(['\n  display: flex;\n  justify-content: space-between;\n '], ['\n  display: flex;\n  justify-content: space-between;\n ']),
    _templateObject2 = _taggedTemplateLiteral(['\n  display: flex;\n  position: relative;\n  padding-right: ', ';\n'], ['\n  display: flex;\n  position: relative;\n  padding-right: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  display: ', ';\n  position: absolute;\n  opacity: .3;\n  width: 100%;\n  height: ', ';\n  background: ', ';\n'], ['\n  display: ', ';\n  position: absolute;\n  opacity: .3;\n  width: 100%;\n  height: ', ';\n  background: ', ';\n']);

exports.default = TextEditor;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CheckboxSwitch = require('./CheckboxSwitch');

var _CheckboxSwitch2 = _interopRequireDefault(_CheckboxSwitch);

var _EditText = require('./EditText');

var _EditText2 = _interopRequireDefault(_EditText);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var namedDiv = function namedDiv(displayName) {
  return _styledComponents2.default.div.withConfig({
    displayName: displayName
  });
};

var TexEditorContainer = namedDiv('TexEditorContainer')(_templateObject);
var EditTextContainer = namedDiv('EditTextContainer')(_templateObject2, function (_ref) {
  var theme = _ref.theme;
  return '' + theme.layout.halfPadding;
});
var Overlay = namedDiv('Overlay')(_templateObject3, function (_ref2) {
  var visible = _ref2.visible;
  return visible ? 'flex' : 'none';
}, function (_ref3) {
  var small = _ref3.small;
  return small ? '100%' : '70%';
}, function (_ref4) {
  var theme = _ref4.theme;
  return '' + theme.colours.secondary02;
});

function TextEditor(_ref5) {
  var onChange = _ref5.onChange,
      text = _ref5.text,
      name = _ref5.name,
      small = _ref5.small,
      hasToggle = _ref5.hasToggle;
  return _react2.default.createElement(TexEditorContainer, null, _react2.default.createElement(EditTextContainer, null, _react2.default.createElement(_EditText2.default, {
    value: text.value,
    editing: text.editing,
    onChange: function (_onChange) {
      function onChange(_x) {
        return _onChange.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange.toString();
      };

      return onChange;
    }(function (value) {
      return onChange(_extends({}, text, {
        value: value
      }));
    }),
    onEdit: function onEdit(editing) {
      return onChange(_extends({}, text, {
        editing: editing
      }));
    },
    onDelete: function onDelete() {},
    editOnly: true,
    small: small
  }), _react2.default.createElement(Overlay, {
    visible: text.disabled,
    small: small
  })), hasToggle && !text.editing ? _react2.default.createElement(_CheckboxSwitch2.default, {
    name: name + '-"text-editor-toggle"',
    onChange: function (_onChange2) {
      function onChange() {
        return _onChange2.apply(this, arguments);
      }

      onChange.toString = function () {
        return _onChange2.toString();
      };

      return onChange;
    }(function () {
      return onChange(_extends({}, text, {
        disabled: !text.disabled
      }));
    }),
    value: !text.disabled
  }) : null);
}

TextEditor.defaultProps = {
  name: '',
  small: false,
  hasToggle: false,
  text: {},
  onChange: function onChange() {}
};