'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EditorHeader;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _iosMore = require('react-icons/lib/io/ios-more');

var _iosMore2 = _interopRequireDefault(_iosMore);

var _ScreenSizePicker = require('./ScreenSizePicker');

var _ScreenSizePicker2 = _interopRequireDefault(_ScreenSizePicker);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'EditorHeader__Container',
  componentId: 'sc-1ed12g6-0'
})(['font-family:', ';background:', ';padding:6px;display:flex;justify-content:space-between;height:40px;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colours.primary03;
});

var Left = _styledComponents2.default.div.withConfig({
  displayName: 'Left'
}).withConfig({
  displayName: 'EditorHeader__Left',
  componentId: 'sc-1ed12g6-1'
})(['display:flex;flex:1 0 0%;']);

var Middle = _styledComponents2.default.div.withConfig({
  displayName: 'Middle'
}).withConfig({
  displayName: 'EditorHeader__Middle',
  componentId: 'sc-1ed12g6-2'
})(['width:150px;display:flex;*{flex:1 0 0%;}']);

var Right = _styledComponents2.default.div.withConfig({
  displayName: 'Right'
}).withConfig({
  displayName: 'EditorHeader__Right',
  componentId: 'sc-1ed12g6-3'
})(['display:flex;flex:1 0 0%;justify-content:flex-end;']);

var Logo = _styledComponents2.default.img.withConfig({
  displayName: 'Logo'
}).withConfig({
  displayName: 'EditorHeader__Logo',
  componentId: 'sc-1ed12g6-4'
})(['max-height:50px;']);

var Brand = _styledComponents2.default.span.withConfig({
  type: 'Brand'
}).withConfig({
  displayName: 'EditorHeader__Brand',
  componentId: 'sc-1ed12g6-5'
})(['color:', ';margin-left:', ';font-size:', ';font-weight:600;text-transform:uppercase;display:flex;align-items:center;'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.disabled01;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.layout.halfPadding;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.fontSizeContent;
});

var Heading = _styledComponents2.default.span.withConfig({
  type: 'Heading'
}).withConfig({
  displayName: 'EditorHeader__Heading',
  componentId: 'sc-1ed12g6-6'
})(['color:', ';font-size:', ";:before{content:'\u2022';margin-right ", ';}margin-left:', ';display:flex;align-items:center;'], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colours.secondary06;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.fontSizeContent;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.layout.halfPadding;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.layout.halfPadding;
});

var ReducedHeightButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'EditorHeader__ReducedHeightButton',
  componentId: 'sc-1ed12g6-7'
})(['height:40px;margin-left:10px;white-space:nowrap;']);
var UnsavedChanges = (0, _styledComponents2.default)(function (props) {
  return _react2.default.createElement('div', props, 'You have unsaved changes');
}).withConfig({
  displayName: 'EditorHeader__UnsavedChanges',
  componentId: 'sc-1ed12g6-8'
})(['height:40px;width:125px;color:', ';'], function (_ref10) {
  var theme = _ref10.theme;
  return theme.colours.disabled01;
});

function SaveChangesButton(_ref11) {
  var saveStatus = _ref11.saveStatus,
      onSaveChanges = _ref11.onSaveChanges;
  var saving = saveStatus === 'saving';
  return [saveStatus === 'unsaved' ? _react2.default.createElement(UnsavedChanges, {
    key: 1
  }) : null, _react2.default.createElement(ReducedHeightButton, {
    key: 2,
    disabled: saving,
    onClick: onSaveChanges
  }, saving ? 'Saving' : 'Save Changes')];
}

var MenuButton = (0, _styledComponents2.default)(ReducedHeightButton).withConfig({
  type: 'MenuButton'
}).withConfig({
  displayName: 'EditorHeader__MenuButton',
  componentId: 'sc-1ed12g6-9'
})(['color:', ';margin-left:', ';text-decoration:none;width:auto;'], function (_ref12) {
  var theme = _ref12.theme;
  return theme.colours.disabled01;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.layout.halfPadding;
});
var Ellipsis = (0, _styledComponents2.default)(_iosMore2.default).withConfig({
  displayName: 'EditorHeader__Ellipsis',
  componentId: 'sc-1ed12g6-10'
})(['font-size:40px;margin:-10px 0;']);

function EditorHeader(_ref14) {
  var logoUrl = _ref14.logoUrl,
      brandName = _ref14.brandName,
      moduleName = _ref14.moduleName,
      onScreenSizeChange = _ref14.onScreenSizeChange,
      onSaveChanges = _ref14.onSaveChanges,
      onPublishClick = _ref14.onPublishClick,
      onMenuClick = _ref14.onMenuClick,
      saveStatus = _ref14.saveStatus,
      selectedScreenSize = _ref14.selectedScreenSize,
      showScreenSizeSelector = _ref14.showScreenSizeSelector;
  return _react2.default.createElement(Container, null, _react2.default.createElement(Left, null, _react2.default.createElement(Logo, {
    src: logoUrl
  }), _react2.default.createElement(Brand, null, brandName), _react2.default.createElement(Heading, null, moduleName || 'Global')), _react2.default.createElement(Middle, null, showScreenSizeSelector && _react2.default.createElement(_ScreenSizePicker2.default, {
    onChange: onScreenSizeChange,
    selected: selectedScreenSize
  })), _react2.default.createElement(Right, null, _react2.default.createElement(SaveChangesButton, {
    onSaveChanges: onSaveChanges,
    saveStatus: saveStatus
  }), _react2.default.createElement(ReducedHeightButton, {
    primary: true,
    onClick: onPublishClick
  }, 'Publish'), _react2.default.createElement(MenuButton, {
    link: true,
    onClick: onMenuClick
  }, _react2.default.createElement(Ellipsis, null))));
}