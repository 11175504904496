'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Input;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Input__Container',
  componentId: 'f77tm6-0'
})(['display:flex;flex-direction:row;align-items:center;']);

var Prefix = _styledComponents2.default.span.withConfig({
  displayName: 'Input__Prefix',
  componentId: 'f77tm6-1'
})(['']);

var StyledInput = _styledComponents2.default.input.withConfig({
  displayName: 'Input'
}).withConfig({
  displayName: 'Input__StyledInput',
  componentId: 'f77tm6-2'
})(['', ';margin:0;display:flex;flex-direction:column;flex:1 0 0%;box-sizing:border-box;', ' max-width:', ';border-width:1px;border-style:solid;', ';', ';', ';&::placeholder{color:', ';}&:focus{border-color:', ';outline:none;appearance:none;~ span{border-color:', ';}}&:disabled{background-color:', ';}'], function (_ref) {
  var theme = _ref.theme,
      large = _ref.large;
  var fs = void 0; // Font size

  var fc = void 0; // Font colour

  var bc = void 0; // Border color

  var pv = void 0; // Padding vertically

  var ph = void 0; // Padding horizontally

  if (large) {
    fs = theme.fontSizeContent;
    fc = theme.colours.secondary05;
    bc = theme.colours.secondary02;
    ph = 10;
    pv = 10;
  } else {
    fs = theme.fontSizeFormControls;
    fc = theme.colours.secondary03;
    bc = theme.colours.secondary03;
    ph = 4;
    pv = 2;
  }

  return (0, _styledComponents.css)(['color:', ';font-size:', ';border-color:', ';padding:', 'px ', 'px ', 'px ', 'px;'], fc, fs, bc, pv, ph, pv, ph);
}, function (_ref2) {
  var prefix = _ref2.prefix,
      inputWidth = _ref2.inputWidth;

  if (inputWidth) {
    return (0, _styledComponents.css)(['width:', 'px;'], inputWidth);
  }

  if (prefix) {
    return (0, _styledComponents.css)(['overflow:hidden;width:100%;']);
  }

  return (0, _styledComponents.css)(['width:auto;']);
}, function (_ref3) {
  var maxInputWidth = _ref3.maxInputWidth;
  return maxInputWidth ? maxInputWidth + 'px' : 'unset';
}, function (props) {
  return props.inputLabelPosition === 'left' && props.hasInputLabel && (0, _styledComponents.css)(['border-left-width:0;order:2;']);
}, function (props) {
  return props.inputLabelPosition === 'right' && props.hasInputLabel && (0, _styledComponents.css)(['border-right-width:0;text-align:right;order:1;']);
}, function (props) {
  return props.error && (0, _styledComponents.css)(['border-color:', ';'], function (_ref4) {
    var theme = _ref4.theme;
    return theme.colours.danger01;
  });
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colours.highlight01;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colours.primary01;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colours.primary01;
}, function (props) {
  return props.theme.colours.disabled02;
});

function Input(_ref8) {
  var name = _ref8.name,
      value = _ref8.value,
      large = _ref8.large,
      error = _ref8.error,
      inputLabelPosition = _ref8.inputLabelPosition,
      hasInputLabel = _ref8.hasInputLabel,
      placeholder = _ref8.placeholder,
      type = _ref8.type,
      onBlur = _ref8.onBlur,
      onChange = _ref8.onChange,
      prefix = _ref8.prefix,
      inputWidth = _ref8.inputWidth,
      maxInputWidth = _ref8.maxInputWidth,
      disabled = _ref8.disabled,
      max = _ref8.max,
      min = _ref8.min,
      step = _ref8.step;

  var input = _react2.default.createElement(StyledInput, {
    inputWidth: inputWidth,
    maxInputWidth: maxInputWidth,
    onBlur: onBlur,
    onChange: onChange,
    inputLabelPosition: inputLabelPosition,
    hasInputLabel: hasInputLabel,
    prefix: prefix,
    placeholder: placeholder,
    type: type === 'text' || type === 'number' ? type : 'text',
    name: name,
    value: value,
    large: large,
    error: error,
    disabled: disabled,
    max: max,
    min: min,
    step: step
  });

  if (!prefix) return input;
  return _react2.default.createElement(Container, null, _react2.default.createElement(Prefix, null, prefix), input);
}

Input.defaultProps = {
  value: '',
  large: false,
  error: false,
  placeholder: '',
  inputLabelPosition: 'left',
  hasInputLabel: false,
  onBlur: function onBlur() {},
  onChange: function onChange() {},
  prefix: false,
  inputWidth: null,
  maxInputWidth: 300,
  disabled: false,
  max: null,
  min: null,
  step: null
};