'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = AnalyticsEditor;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

var _InputField = require('./InputField');

var _InputField2 = _interopRequireDefault(_InputField);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // ideally this would be { label: string, value: Provider } but flow says no
// https://github.com/facebook/flow/issues/2436


var options = [{
  label: 'Google Analytics',
  value: 'Google'
}, {
  label: 'Omniture',
  value: 'Omniture'
}, {
  label: 'Mixpanel',
  value: 'Mixpanel'
}];

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'AnalyticsEditor__Container',
  componentId: 'wkjel5-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var InputContainer = _styledComponents2.default.div.withConfig({
  displayName: 'InputContainer'
}).withConfig({
  displayName: 'AnalyticsEditor__InputContainer',
  componentId: 'wkjel5-1'
})(['margin-top:', ';'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.layout.halfPadding;
});

function AnalyticsEditor(_ref4) {
  var value = _ref4.value,
      onChange = _ref4.onChange;

  var onSelectProvider = function onSelectProvider(provider) {
    return onChange(_extends({}, value, {
      provider: provider
    }));
  };

  var onKeyChange = function onKeyChange(key) {
    return onChange(_extends({}, value, {
      key: key
    }));
  };

  return _react2.default.createElement(Container, null, _react2.default.createElement(_Select2.default, {
    placeholder: 'Select analytics provider',
    name: 'analytics-select',
    options: options,
    onChange: function onChange(e) {
      return onSelectProvider(e.target.value);
    },
    selectedValue: value ? value.provider : '',
    thin: true
  }), _react2.default.createElement(InputContainer, null, _react2.default.createElement(_InputField2.default, {
    type: 'text',
    name: 'analytics-api-key',
    placeholder: 'Paste API key here',
    prefix: null,
    input: {
      onChange: function onChange(e) {
        return onKeyChange(e.target.value);
      },
      value: value ? value.key : ''
    }
  })));
}

AnalyticsEditor.defaultProps = {
  value: {
    provider: '',
    key: ''
  }
};