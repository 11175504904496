'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionMenuBack;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronLeft = require('react-icons/lib/io/chevron-left');

var _chevronLeft2 = _interopRequireDefault(_chevronLeft);

var _transparentize = require('polished/lib/color/transparentize');

var _transparentize2 = _interopRequireDefault(_transparentize);

var _FlexWrapper = require('./FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Body = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuBack__Body',
  componentId: 'sc-1cjx5dh-0'
})(['font-family:', ';color:', ';display:flex;font-size:', ';justify-content:space-between;align-items:center;box-sizing:border-box;height:32px;padding:', ';background-color:#33a8ef;cursor:pointer;'], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colours.secondary01;
}, function (props) {
  return props.theme.fontSizeContent;
}, function (props) {
  return props.theme.layout.standardPadding;
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'SectionMenuBack__Title',
  componentId: 'sc-1cjx5dh-1'
})(['color:', ';font-size:', ';line-height:17px;font-weight:600;margin-left:', ';'], function (props) {
  return (0, _transparentize2.default)(0.5, props.theme.colours.primary03);
}, function (props) {
  return props.theme.fontSizeFormControls;
}, function (props) {
  return props.theme.layout.halfPadding;
});

function SectionMenuBack(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick;
  return _react2.default.createElement(Body, {
    onClick: onClick
  }, _react2.default.createElement(_chevronLeft2.default, {
    size: '9px'
  }), _react2.default.createElement(_FlexWrapper2.default, null, _react2.default.createElement(Title, null, children)));
}