"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var DragHandle = function DragHandle(props) {
  return _react2.default.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 14 12"
  }, props), _react2.default.createElement("path", {
    d: "M14 11a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1zm0-5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1zm0-5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1z",
    fill: "#757575",
    fillRule: "evenodd"
  }));
};

exports.default = DragHandle;