// @flow
const appSettings = window.AppSettings || {};
const apiHostProtocol = 'https';
const hostUrl = appSettings.baseUrl || 'dev.mtkip.com';

const ADMIN_BASE_URL = `${apiHostProtocol}://avl-admin.${hostUrl}/api`;

export const API_BASE_URL_BRANDS = `${ADMIN_BASE_URL}/tenants`;
export const API_BASE_URL_USERS = `${apiHostProtocol}://avl-users.${hostUrl}/api/users`;
export const API_BASE_URL_LANGUAGES = `${ADMIN_BASE_URL}/languages`;

export const API_BASE_URL_SITES = brandId => `${API_BASE_URL_BRANDS}/${brandId}/sites`;
