'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Dropdown = require('./Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _ColourSelection = require('./ColourSelection');

var _ColourSelection2 = _interopRequireDefault(_ColourSelection);

var _InputField = require('./InputField');

var _InputField2 = _interopRequireDefault(_InputField);

var _TextTransform = require('./TextTransform');

var _TextTransform2 = _interopRequireDefault(_TextTransform);

var _TextAlignment = require('./TextAlignment');

var _TextAlignment2 = _interopRequireDefault(_TextAlignment);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var Container = _styledComponents2.default.div.attrs({
  type: 'Container'
}).withConfig({
  displayName: 'FontEditor__Container',
  componentId: 'dbjtoy-0'
})(['font-family:', ';font-size:', ';display:flex;flex-direction:column;'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontFamily;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontSizeContent;
});

var Label = _styledComponents2.default.span.attrs({
  type: 'Label'
}).withConfig({
  displayName: 'FontEditor__Label',
  componentId: 'dbjtoy-1'
})(['color:', ';font-size:', ';font-weight:600;text-transform:capitalize;display:flex;align-items:center;'], function (_ref3) {
  var theme = _ref3.theme;
  return theme.colours.secondary03;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.fontSizeFormLabel;
});

var Row = _styledComponents2.default.div.attrs({
  type: 'Row'
}).withConfig({
  displayName: 'FontEditor__Row',
  componentId: 'dbjtoy-2'
})(['display:flex;justify-content:space-between;align-items:center;margin-bottom:', ';*{', ';}'], function (_ref5) {
  var theme = _ref5.theme;
  return theme.layout.halfPadding;
}, function (_ref6) {
  var expandChildren = _ref6.expandChildren;
  return expandChildren ? 'flex: 1 0 0%' : '';
});

var Bullet = _styledComponents2.default.span.attrs({
  type: 'Bullet'
}).withConfig({
  displayName: 'FontEditor__Bullet',
  componentId: 'dbjtoy-3'
})(['color:', ";:before{content:'\u2022';margin-right ", ';font-size ', ';}'], function (_ref7) {
  var theme = _ref7.theme;
  return theme.colours.disabled01;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.layout.halfPadding;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.bulletPointSize;
});

var FixedWidthBox = _styledComponents2.default.div.withConfig({
  displayName: 'FontEditor__FixedWidthBox',
  componentId: 'dbjtoy-4'
})(['width:', ';'], function (_ref10) {
  var width = _ref10.width;
  return width + 'px';
});

var Left = _styledComponents2.default.div.withConfig({
  displayName: 'Left'
}).withConfig({
  displayName: 'FontEditor__Left',
  componentId: 'dbjtoy-5'
})(['display:flex;flex:3 0 0%;']);

var Right = _styledComponents2.default.div.withConfig({
  displayName: 'Right'
}).withConfig({
  displayName: 'FontEditor__Right',
  componentId: 'dbjtoy-6'
})(['display:flex;flex:1 0 0%;justify-content:flex-end;']);

var FlexGrowOne = _styledComponents2.default.span.withConfig({
  displayName: 'FontEditor__FlexGrowOne',
  componentId: 'dbjtoy-7'
})(['flex-grow:1;']);

var FontEditor = function (_React$Component) {
  _inherits(FontEditor, _React$Component);

  function FontEditor(props) {
    _classCallCheck(this, FontEditor);

    var _this = _possibleConstructorReturn(this, (FontEditor.__proto__ || Object.getPrototypeOf(FontEditor)).call(this, props));

    _this.state = {
      textTransformOpen: false
    };
    _this.openCloseTextTransform = _this.unboundOpenCloseTextTransform.bind(_this);
    return _this;
  }

  _createClass(FontEditor, [{
    key: 'unboundOpenCloseTextTransform',
    value: function unboundOpenCloseTextTransform(open) {
      this.setState({
        textTransformOpen: open
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          name = _props.name,
          label = _props.label,
          colours = _props.colours,
          typefaces = _props.typefaces,
          value = _props.value,
          onChange = _props.onChange,
          withParagraphAlignment = _props.withParagraphAlignment;

      var patch = function patch(change) {
        return _extends({}, value, change);
      };

      return _react2.default.createElement(Container, null, _react2.default.createElement(Row, null, _react2.default.createElement(Label, null, label), _react2.default.createElement(FlexGrowOne, null, _react2.default.createElement(_ColourSelection2.default, {
        input: {
          value: value.colour,
          onChange: function (_onChange) {
            function onChange(_x) {
              return _onChange.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange.toString();
            };

            return onChange;
          }(function (colour) {
            return colour && onChange(patch({
              colour: colour
            }));
          })
        },
        options: colours
      }))), _react2.default.createElement(Row, {
        expandChildren: true
      }, _react2.default.createElement(_Dropdown2.default, {
        name: name + '-font-typeface-selector',
        input: {
          value: value.typeface,
          onChange: function (_onChange2) {
            function onChange(_x2) {
              return _onChange2.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange2.toString();
            };

            return onChange;
          }(function (typeface) {
            return onChange(patch({
              typeface: typeface || {
                value: null
              }
            }));
          }),
          placeholder: 'Template Font'
        },
        options: typefaces
      })), _react2.default.createElement(Row, null, _react2.default.createElement(Left, null, _react2.default.createElement(Bullet, null, 'Font size')), _react2.default.createElement(Right, null, _react2.default.createElement(_InputField2.default, {
        maxInputWidth: 40,
        type: 'number',
        inputLabel: 'px',
        inputLabelPosition: 'right',
        input: {
          onChange: function (_onChange3) {
            function onChange(_x3) {
              return _onChange3.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange3.toString();
            };

            return onChange;
          }(function (e) {
            return onChange(patch({
              fontSize: e.target.value
            }));
          }),
          name: name + '-font-size-input',
          value: value.fontSize
        },
        inputFlexMode: '1'
      }))), _react2.default.createElement(Row, null, _react2.default.createElement(Left, null, _react2.default.createElement(Bullet, null, 'Character spacing')), _react2.default.createElement(Right, null, _react2.default.createElement(_InputField2.default, {
        maxInputWidth: 40,
        type: 'number',
        inputLabel: 'px',
        inputLabelPosition: 'right',
        input: {
          onChange: function (_onChange4) {
            function onChange(_x4) {
              return _onChange4.apply(this, arguments);
            }

            onChange.toString = function () {
              return _onChange4.toString();
            };

            return onChange;
          }(function (e) {
            return onChange(patch({
              kerning: e.target.value
            }));
          }),
          name: name + '-font-char-spacing-input',
          value: value.kerning
        },
        inputFlexMode: '1'
      }))), _react2.default.createElement(Row, null, _react2.default.createElement(Bullet, null, 'Transform case'), _react2.default.createElement(FixedWidthBox, {
        width: 70
      }, _react2.default.createElement(_TextTransform2.default, {
        name: name + '-font-text-transform',
        value: value.transform,
        open: this.state.textTransformOpen,
        onClose: function onClose() {
          return _this2.openCloseTextTransform(false);
        },
        onOpen: function onOpen() {
          return _this2.openCloseTextTransform(true);
        },
        onChange: function (_onChange5) {
          function onChange(_x5) {
            return _onChange5.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange5.toString();
          };

          return onChange;
        }(function (transform) {
          return onChange(patch({
            transform: transform
          }));
        }),
        inline: true
      }))), !withParagraphAlignment ? null : _react2.default.createElement(Row, null, _react2.default.createElement(Bullet, null, 'Paragraph'), _react2.default.createElement(FixedWidthBox, {
        width: 70
      }, _react2.default.createElement(_TextAlignment2.default, {
        name: name + '-font-text-alignment',
        value: value.alignment,
        open: false,
        onChange: function (_onChange6) {
          function onChange(_x6) {
            return _onChange6.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange6.toString();
          };

          return onChange;
        }(function (alignment) {
          return onChange(patch({
            alignment: alignment
          }));
        }),
        inline: true
      }))));
    }
  }]);

  return FontEditor;
}(_react2.default.Component);

FontEditor.defaultProps = {
  label: null,
  withParagraphAlignment: false
};
exports.default = FontEditor;