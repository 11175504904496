'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _trashB = require('react-icons/lib/io/trash-b');

var _trashB2 = _interopRequireDefault(_trashB);

var _edit = require('react-icons/lib/io/edit');

var _edit2 = _interopRequireDefault(_edit);

var _locked = require('react-icons/lib/io/locked');

var _locked2 = _interopRequireDefault(_locked);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var EditTextContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EditTextContainer'
}).withConfig({
  displayName: 'EditText__EditTextContainer',
  componentId: 'ycu5tb-0'
})(['display:flex;flex:1 0 0%;align-items:stretch;margin-bottom:', ';width:90%;'], function (props) {
  return props.small ? '0' : props.theme.layout.standardPadding;
});

var Input = _styledComponents2.default.input.withConfig({
  displayName: 'InputField'
}).withConfig({
  displayName: 'EditText__Input',
  componentId: 'ycu5tb-1'
})(['flex:1 0 0%;background:transparent;width:', ';border:', ';position:relative;margin:0 4px 0 0;font-family:', ';font-size:', ';white-space:nowrap;overflow:hidden;text-overflow:ellipsis;user-select:', ';&::placeholder{color:', ';}&:focus{border-color:', ';outline:none;appearance:none;}'], function (props) {
  return props.small ? '100%' : 'auto';
}, function (props) {
  return props.disabled ? 'none' : '1px solid';
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontSizeContent;
}, function (props) {
  return props.disabled ? 'none' : 'text';
}, function (props) {
  return props.theme.colours.placeholder;
}, function (_ref) {
  var theme = _ref.theme;
  return theme.colours.primary01;
});

var EditIconsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EditIconsContainer'
}).withConfig({
  displayName: 'EditText__EditIconsContainer',
  componentId: 'ycu5tb-2'
})(['display:flex;']);

var EditIcon = (0, _styledComponents2.default)(_edit2.default).withConfig({
  displayName: 'EditIcon'
}).withConfig({
  displayName: 'EditText__EditIcon',
  componentId: 'ycu5tb-3'
})(['color:', ';padding-left:', ';cursor:pointer;'], function (props) {
  return props.theme.colours.primary01;
}, function (props) {
  return props.small ? '0' : props.theme.layout.standardPadding;
});
var DeleteIcon = (0, _styledComponents2.default)(_trashB2.default).withConfig({
  displayName: 'DeleteIcon'
}).withConfig({
  displayName: 'EditText__DeleteIcon',
  componentId: 'ycu5tb-4'
})(['color:', ';padding-left:', ';cursor:pointer;'], function (props) {
  return props.theme.colours.primary01;
}, function (props) {
  return props.small ? '0' : props.theme.layout.standardPadding;
});
var LockIcon = (0, _styledComponents2.default)(_locked2.default).withConfig({
  displayName: 'LockIcon'
}).withConfig({
  displayName: 'EditText__LockIcon',
  componentId: 'ycu5tb-5'
})(['color:', ';padding-left:', ';'], function (props) {
  return props.theme.colours.primary01;
}, function (props) {
  return props.small ? '0' : props.theme.layout.standardPadding;
});
var DoneButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'DoneButton'
}).withConfig({
  displayName: 'EditText__DoneButton',
  componentId: 'ycu5tb-6'
})(['padding:6px 10px;']);

function EditIcons(_ref2) {
  var handleEdit = _ref2.onEditClick,
      handleDelete = _ref2.onDeleteClick,
      lockDelete = _ref2.lockDelete,
      editOnly = _ref2.editOnly,
      small = _ref2.small;
  return _react2.default.createElement(EditIconsContainer, null, _react2.default.createElement(EditIcon, {
    small: small,
    onClick: handleEdit
  }), !editOnly && (lockDelete ? _react2.default.createElement(LockIcon, {
    small: true
  }) : _react2.default.createElement(DeleteIcon, {
    small: true,
    onClick: handleDelete
  })));
}

var EditText = function (_React$Component) {
  _inherits(EditText, _React$Component);

  function EditText() {
    var _ref3;

    var _temp, _this, _ret;

    _classCallCheck(this, EditText);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref3 = EditText.__proto__ || Object.getPrototypeOf(EditText)).call.apply(_ref3, [this].concat(args))), _this), _this.element = null, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(EditText, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.focusOnEdit();
    } // Element must be focussed *after* update to ensure that the element we are focussing is rendered
    // (and/or will not be immediately re-rendered).

  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(_ref4) {
      var editing = _ref4.editing;

      if (!editing) {
        this.focusOnEdit();
      }
    }
  }, {
    key: 'focusOnEdit',
    value: function focusOnEdit() {
      if (this.element && this.props.editing) {
        this.element.focus();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          name = _props.name,
          value = _props.value,
          editing = _props.editing,
          onChange = _props.onChange,
          onEdit = _props.onEdit,
          onDelete = _props.onDelete,
          lockDelete = _props.lockDelete,
          editOnly = _props.editOnly,
          small = _props.small;
      return _react2.default.createElement(EditTextContainer, {
        small: small
      }, _react2.default.createElement(Input, {
        small: small,
        name: name,
        value: value,
        type: 'text',
        disabled: !editing,
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (event) {
          return onChange(event.target.value);
        }),
        ref: function ref(input) {
          _this2.element = input;
        }
      }), editing ? _react2.default.createElement(DoneButton, {
        small: true,
        primary: true,
        textTransform: 'uppercase',
        onClick: function onClick() {
          return onEdit(false);
        },
        disabled: value === ''
      }, 'Done') : _react2.default.createElement(EditIcons, {
        small: small,
        onEditClick: function onEditClick() {
          return onEdit(true);
        },
        onDeleteClick: onDelete,
        lockDelete: lockDelete,
        editOnly: editOnly
      }));
    }
  }]);

  return EditText;
}(_react2.default.Component);

EditText.defaultProps = {
  name: 'Edit Text',
  onChange: function onChange() {},
  onEdit: function onEdit() {},
  onDelete: function onDelete() {},
  lockDelete: false,
  editOnly: false,
  small: false
};
exports.default = EditText;