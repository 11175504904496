'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EditMany;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

var _DeleteButton = require('./DeleteButton');

var _DeleteButton2 = _interopRequireDefault(_DeleteButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'Container'
}).withConfig({
  displayName: 'EditMany__Container',
  componentId: 'ng980r-0'
})(['display:flex;flex-direction:column;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Row'
}).withConfig({
  displayName: 'EditMany__Row',
  componentId: 'ng980r-1'
})(['display:flex;flex-direction:row;align-items:center;justify-content:space-between;margin-bottom:', ';'], function (props) {
  return props.theme.layout.doublePadding;
});

var DeleteWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'EditMany__DeleteWrapper',
  componentId: 'ng980r-2'
})(['display:flex;justify-content:center;min-width:20px;']);

var EditorWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'EditMany__EditorWrapper',
  componentId: 'ng980r-3'
})(['overflow:hidden;padding:1px;width:100%;']);

var AddButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'EditMany__AddButtonWrapper',
  componentId: 'ng980r-4'
})(['display:flex;flex-direction:row-reverse;margin-top:', ';margin-right:20px;'], function (props) {
  return '-' + props.theme.layout.doublePadding;
});

var ItemView = function ItemView(_ref) {
  var renderEditor = _ref.renderEditor,
      item = _ref.item,
      onChange = _ref.onChange,
      showDelete = _ref.showDelete,
      onDelete = _ref.onDelete;
  return _react2.default.createElement(Row, null, _react2.default.createElement(EditorWrapper, null, renderEditor(item, onChange)), _react2.default.createElement(DeleteWrapper, null, showDelete && _react2.default.createElement(_DeleteButton2.default, {
    onClick: onDelete,
    small: true
  })));
};

function EditMany(_ref2) {
  var _ref2$items = _ref2.items,
      items = _ref2$items === undefined ? [] : _ref2$items,
      renderEditor = _ref2.renderEditor,
      onAdd = _ref2.onAdd,
      onDelete = _ref2.onDelete,
      onItemChange = _ref2.onItemChange,
      idFactory = _ref2.idFactory;
  var internalItems = items.length > 0 ? items : [{
    id: idFactory(),
    value: null
  }];
  return _react2.default.createElement(Container, null, internalItems.map(function (item) {
    return _react2.default.createElement(ItemView, {
      key: item.id,
      renderEditor: renderEditor,
      item: item,
      onChange: function onChange(value) {
        return onItemChange(item.id, value);
      },
      onDelete: function (_onDelete) {
        function onDelete() {
          return _onDelete.apply(this, arguments);
        }

        onDelete.toString = function () {
          return _onDelete.toString();
        };

        return onDelete;
      }(function () {
        return onDelete(item.id);
      }),
      showDelete: item.value !== null
    });
  }), _react2.default.createElement(AddButtonWrapper, null, _react2.default.createElement(_Button2.default, {
    link: true,
    primary: true,
    onClick: onAdd
  }, 'Add another')));
}