'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _index = require('../EditableList/index');

var _index2 = _interopRequireDefault(_index);

var _SelectSubmit = require('../SelectSubmit');

var _SelectSubmit2 = _interopRequireDefault(_SelectSubmit);

var _Button = require('../Button');

var _Button2 = _interopRequireDefault(_Button);

var _itemsHelper = require('./items-helper');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var SelectAddItem = (0, _styledComponents2.default)(_SelectSubmit2.default).withConfig({
  displayName: 'SelectAddItem'
}).withConfig({
  displayName: 'ItemsEditor__SelectAddItem',
  componentId: 'sc-186xfc7-0'
})(['']);
var AddButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: 'AddButton'
}).withConfig({
  displayName: 'ItemsEditor__AddButton',
  componentId: 'sc-186xfc7-1'
})(['']);

var ItemsEditor = function (_Component) {
  _inherits(ItemsEditor, _Component);

  function ItemsEditor() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ItemsEditor);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ItemsEditor.__proto__ || Object.getPrototypeOf(ItemsEditor)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      ItemBeingEdited: null,
      showAddItem: false
    }, _this.toggleAddItem = function () {
      _this.setState(function () {
        return {
          showAddItem: !_this.state.showAddItem
        };
      });
    }, _this.handleEdit = function (navId, editing) {
      _this.setState({
        ItemBeingEdited: editing ? navId : null
      });
    }, _this.handleAdd = function (id, data, items) {
      _this.toggleAddItem();

      return (0, _itemsHelper.addItem)(id, data, items);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ItemsEditor, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          name = _props.name,
          items = _props.value,
          onChange = _props.onChange,
          options = _props.options;
      return _react2.default.createElement('div', null, _react2.default.createElement(_index2.default, {
        items: items,
        collection: items,
        itemBeingEdited: this.state.ItemBeingEdited,
        onEdit: function onEdit(navId) {
          return function (editing) {
            return _this2.handleEdit(navId, editing);
          };
        },
        onChange: function (_onChange) {
          function onChange(_x) {
            return _onChange.apply(this, arguments);
          }

          onChange.toString = function () {
            return _onChange.toString();
          };

          return onChange;
        }(function (navId) {
          return function (value) {
            return onChange((0, _itemsHelper.updateItem)(navId, value, items));
          };
        }),
        onDelete: function onDelete(navId) {
          return function () {
            return onChange((0, _itemsHelper.handleDelete)(navId, items));
          };
        },
        onSort: function onSort(_ref2) {
          var oldIndex = _ref2.oldIndex,
              newIndex = _ref2.newIndex;
          return onChange((0, _itemsHelper.handleSort)(items, oldIndex, newIndex));
        }
      }), this.state.showAddItem ? _react2.default.createElement(SelectAddItem, {
        placeholder: 'Select a page/link',
        name: name,
        options: options.map(function (_ref3) {
          var label = _ref3.label,
              id = _ref3.id;
          return {
            value: id,
            label: label
          };
        }),
        onSubmit: function onSubmit(id) {
          return onChange(_this2.handleAdd(id, options, items));
        }
      }) : _react2.default.createElement(AddButton, {
        small: true,
        fullWidth: true,
        textTransform: 'uppercase',
        primary: true,
        onClick: this.toggleAddItem
      }, 'Add page/link'));
    }
  }]);

  return ItemsEditor;
}(_react.Component);

exports.default = ItemsEditor;