'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FlexWrapper;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var StyledFlexWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'StyledFlexWrapper'
}).withConfig({
  displayName: 'FlexWrapper__StyledFlexWrapper',
  componentId: 'obxetb-0'
})(['flex:1 0 0%;']);

function FlexWrapper(props) {
  return _react2.default.createElement(StyledFlexWrapper, props);
}