'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FieldLabel;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var FieldRow = _styledComponents2.default.div.withConfig({
  displayName: 'FieldRow'
}).withConfig({
  displayName: 'FieldLabel__FieldRow',
  componentId: 'sc-1deanwh-0'
})(['display:flex;justify-content:space-between;align-items:center;']);

var LabelCell = _styledComponents2.default.div.withConfig({
  displayName: 'LabelCell'
}).withConfig({
  displayName: 'FieldLabel__LabelCell',
  componentId: 'sc-1deanwh-1'
})(['font-weight:bold;margin-right:8px;font-size:', ';'], function (_ref) {
  var theme = _ref.theme;
  return theme.fontSizeFormLabel;
});

var InputCell = _styledComponents2.default.div.withConfig({
  display: 'InputCell'
}).withConfig({
  displayName: 'FieldLabel__InputCell',
  componentId: 'sc-1deanwh-2'
})(['flex-grow:', ';'], function (_ref2) {
  var inputFlexMode = _ref2.inputFlexMode;
  return '' + inputFlexMode;
});

function FieldLabel(_ref3) {
  var children = _ref3.children,
      label = _ref3.label,
      inputFlexMode = _ref3.inputFlexMode;
  return _react2.default.createElement(FieldRow, null, _react2.default.createElement(LabelCell, null, label), _react2.default.createElement(InputCell, {
    inputFlexMode: inputFlexMode
  }, children));
}

FieldLabel.defaultProps = {
  inputFlexMode: '1'
};