'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccordionList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _chevronDown = require('react-icons/lib/io/chevron-down');

var _chevronDown2 = _interopRequireDefault(_chevronDown);

var _chevronUp = require('react-icons/lib/io/chevron-up');

var _chevronUp2 = _interopRequireDefault(_chevronUp);

var _IconBox = require('../IconBox');

var _IconBox2 = _interopRequireDefault(_IconBox);

var _FlexWrapper = require('../FlexWrapper');

var _FlexWrapper2 = _interopRequireDefault(_FlexWrapper);

var _Button = require('../Button');

var _Button2 = _interopRequireDefault(_Button);

var _DeleteIcon = require('./DeleteIcon');

var _DeleteIcon2 = _interopRequireDefault(_DeleteIcon);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ItemContainer'
}).withConfig({
  displayName: 'AccordionList__ItemContainer',
  componentId: 'fw5az0-0'
})(['font-family:', ';display:flex;flex-direction:column;padding:0;overflow:', ';'], function (props) {
  return props.theme.fontFamily;
}, function (_ref) {
  var open = _ref.open;
  return open ? '' : 'hidden';
});

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'Title'
}).withConfig({
  displayName: 'AccordionList__Title',
  componentId: 'fw5az0-1'
})(['color:', ';display:flex;font-size:14px;justify-content:space-between;align-items:center;height:14px;font-weight:600;padding:', ';background-color:', ';', ';'], function (props) {
  return props.theme.colours.secondary03;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.colours.primary03;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.mixins.boxShadow(theme.colours.primary02, 0.6, 0, 0, 8, 0, 0);
});

var IsOpenIcon = (0, _styledComponents2.default)(_chevronUp2.default).withConfig({
  displayName: 'IsOpenIcon'
}).withConfig({
  displayName: 'AccordionList__IsOpenIcon',
  componentId: 'fw5az0-2'
})(['']);
var IsClosedIcon = (0, _styledComponents2.default)(_chevronDown2.default).withConfig({
  displayName: 'IsClosedIcon'
}).withConfig({
  displayName: 'AccordionList__IsClosedIcon',
  componentId: 'fw5az0-3'
})(['']);
var StyledIconBox = (0, _styledComponents2.default)(_IconBox2.default).withConfig({
  displayName: 'AccordionList__StyledIconBox',
  componentId: 'fw5az0-4'
})(['&:hover{cursor:pointer;}']);

var ContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ContentContainer'
}).withConfig({
  displayName: 'AccordionList__ContentContainer',
  componentId: 'fw5az0-5'
})(['margin:1px 0 0 ', ';max-height:', ';'], function (props) {
  return props.theme.layout.doublePadding;
}, function (_ref3) {
  var visible = _ref3.visible;
  return visible ? 'auto' : '0';
});

var ContentMargin = _styledComponents2.default.div.withConfig({
  displayName: 'AccordionList__ContentMargin',
  componentId: 'fw5az0-6'
})(['border-left:1px solid ', ';padding:0 ', ';margin:', ' 0;'], function (props) {
  return props.theme.colours.secondary02;
}, function (props) {
  return props.theme.layout.standardPadding;
}, function (props) {
  return props.theme.layout.standardPadding;
});

var DeleteButton = (0, _styledComponents2.default)(_DeleteIcon2.default).withConfig({
  displayName: 'DeleteButton'
}).withConfig({
  displayName: 'AccordionList__DeleteButton',
  componentId: 'fw5az0-7'
})(['color:', ';cursor:pointer;'], function (props) {
  return props.theme.colours.primary01;
});

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'ButtonContainer'
}).withConfig({
  displayName: 'AccordionList__ButtonContainer',
  componentId: 'fw5az0-8'
})(['display:flex;flex-direction:column;margin:', ';'], function (props) {
  return props.theme.layout.standardPadding;
});

var AccordionItem = function AccordionItem(_ref4) {
  var titleContents = _ref4.titleContents,
      bodyContents = _ref4.bodyContents,
      open = _ref4.open,
      onToggle = _ref4.onToggle,
      onDelete = _ref4.onDelete;
  return _react2.default.createElement(ItemContainer, {
    open: open
  }, _react2.default.createElement(Title, null, _react2.default.createElement(_FlexWrapper2.default, null, titleContents), _react2.default.createElement(DeleteButton, {
    onClick: onDelete
  }), _react2.default.createElement(StyledIconBox, {
    onClick: onToggle
  }, open ? _react2.default.createElement(IsOpenIcon, null) : _react2.default.createElement(IsClosedIcon, null))), _react2.default.createElement(ContentContainer, {
    visible: open
  }, _react2.default.createElement(ContentMargin, null, bodyContents)));
};

function AccordionList(_ref5) {
  var onAdd = _ref5.onAdd,
      addText = _ref5.addText,
      items = _ref5.items,
      onToggle = _ref5.onToggle,
      onDelete = _ref5.onDelete,
      disableOn = _ref5.disableOn;
  return _react2.default.createElement('div', null, (items || []).map(function (_ref6, index) {
    var titleContents = _ref6.titleContents,
        bodyContents = _ref6.bodyContents,
        open = _ref6.open;
    return _react2.default.createElement(AccordionItem, {
      key: 'item-' + (index + 1),
      titleContents: titleContents,
      bodyContents: bodyContents,
      open: open,
      onToggle: function (_onToggle) {
        function onToggle() {
          return _onToggle.apply(this, arguments);
        }

        onToggle.toString = function () {
          return _onToggle.toString();
        };

        return onToggle;
      }(function () {
        return onToggle(index);
      }),
      onDelete: function (_onDelete) {
        function onDelete() {
          return _onDelete.apply(this, arguments);
        }

        onDelete.toString = function () {
          return _onDelete.toString();
        };

        return onDelete;
      }(function () {
        return onDelete(index);
      })
    });
  }), _react2.default.createElement(ButtonContainer, null, _react2.default.createElement(_Button2.default, {
    primary: true,
    small: true,
    fullWidth: true,
    textTransform: 'uppercase',
    onClick: onAdd,
    disabled: disableOn(items)
  }, addText)));
}